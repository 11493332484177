@use '@angular/material' as mat;

$custom-typography: mat.m2-define-typography-config(
  $font-family: 'Inter'
);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.all-component-typographies($custom-typography);
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-layerjot-primary: (
    50 : #ecf2fe,
    100 : #cfe0fc,
    200 : #b0cbfb,
    300 : #90b6f9,
    400 : #78a6f7,
    500 : #6096f6,
    600 : #588ef5,
    700 : #4e83f3,
    800 : #4479f2,
    900 : #3368ef,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #cedbff,
    A700 : #b5c8ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-layerjot-accent: (
    50 : #feeee4,
    100 : #fcd4bc,
    200 : #fab890,
    300 : #f79c64,
    400 : #f68642,
    500 : #f47121,
    600 : #f3691d,
    700 : #f15e18,
    800 : #ef5414,
    900 : #ec420b,
    A100 : #ffffff,
    A200 : #ffe9e3,
    A400 : #ffc0b0,
    A700 : #ffab96,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



$my-primary: mat.m2-define-palette($md-layerjot-primary);
$my-accent: mat.m2-define-palette($md-layerjot-accent);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);

$my-theme: mat.m2-define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn,
 ),
  typography: mat.m2-define-typography-config(
    $font-family: 'Inter'
  )
));

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);
