@import "./assets/styles/loading.scss";
@import "./assets/styles/mixins/spacing.mixin.scss";
@import "bootstrap/dist/css/bootstrap.min.css";
@import "./assets/styles/theme/theme.scss";
@import "ngx-toastr/toastr";
@import "./assets/styles/mixins/spacing.mixin.scss";

@import 'swiper/scss';
@import 'swiper/scss/pagination';
@import 'swiper/scss/navigation';

@include generate(ml, margin-left);
@include generate(mr, margin-right);
@include generate(pl, padding-left);
@include generate(pr, padding-right);

html,
body {
  height: 100%;
  -moz-osx-font-smoothing: inherit !important;
  -webkit-font-smoothing: none !important;
}
body {
  margin: 0;
  font-family: Inter, "Helvetica Neue", sans-serif !important;
}

button {
  font-family: Inter, "Helvetica Neue", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0.1px;
}

h4 {
  font-size: 20px;
}

.lh0 {
  line-height: 0;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml1px {
  margin-left: 1px;
}

// ─────────────────────────────────────────────────────────────────────────────
// Update container max width
.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 1420px;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #aaaaaa #ffffff;

  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto !important;

  --mat-tab-header-label-text-tracking: 0.1;
  --mat-radio-label-text-size: 16px;

  --mat-menu-item-label-text-size: 14px;

  --mat-dialog-container-elevation-shadow: 0 0 0;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 6px;
  border: 3px solid #ffffff;
}

.dev_highlight {
  outline: 1px solid red;
  width: 100%;
  height: 100%;
}

// Custom buttons
.mat-mdc-button {
  --mdc-text-button-label-text-tracking: 0.01;
  --mdc-text-button-container-height: 44px;
  --mat-text-button-with-icon-horizontal-padding: 15px;

  &.tray-button {
    min-width: 131px;
    height: 48px;
    border-radius: 48px;
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: center;

    &.medium {
      height: 42px;
      font-size: 14px;
      line-height: 32px;
    }
    &.small {
      height: 32px;
      font-size: 14px;
      line-height: 32px;
    }
    &.dark-blue {
      background-color: #1e5598;
      color: #f2f3f8;
    }
    &.blue {
      background-color: #6096f6;
      color: #f2f3f8;
    }
    &.orange {
      background-color: #f47121;
      color: #f2f3f8;
    }
    &.grey {
      background-color: #828282;
      color: #f2f3f8;
    }
    &.red {
      background-color: #f5515f;
      color: #f2f3f8;
    }
    &.transparent {
      background-color: transparent;
      color: #97989b;
    }
    &.light-grey {
      background-color: #f2f3f8;
      color: #5b5e71;
    }
    &[disabled] {
      color: #5b5e71;
      background-color: lightgray !important;
    }
  }
}

.w100 {
  width: 100% !important;
}

.w99 {
  width: 99% !important;
}

.w98 {
  width: 98% !important;
}

.w50 {
  width: 50% !important;
}

.h100 {
  height: 100% !important;
}

[hidden] {
  display: none !important;
}

.error-message {
  color: rgba(255, 255, 255, 1);
  background: #a01126;
}

main {
  display: block;
  min-height: calc(100vh - 210px);
  overflow: auto;
  position: relative;
}

.offline-page-wrapper {
  min-height: calc(100vh - 170px);
  overflow: auto;
}

// Static pages
.offline-page,
.internal-server-error,
.page-not-found,
.not-authenticated {
  text-align: center;
  height: calc(100vh - 128px);
}

.add-tray-modal {
  .grey-text {
    color: #97989b;
    font-size: 16px;
    line-height: 32px;
  }
}

.file-upload {
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  float: right;
  &-fail {
    background-image: url(./assets/icons/file-upload-fail.svg);
  }
  &-success {
    background-image: url(./assets/icons/file-upload-success.svg);
  }
}

.delete-success {
  position: relative;
  margin: 0 auto;
  width: 163px;
  height: 163px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  background-image: url(./assets/icons/tray-delete-success.svg);
}

.tray-icon {
  width: 42px;
  height: 42px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  &.small {
    width: 32px;
    height: 32px;
  }
  &.pdf {
    background-image: url(./assets/icons/pdf.svg);
  }
  &.scan {
    background-image: url(./assets/icons/scan.svg);
  }
  &.xls {
    background-image: url(./assets/icons/xls.svg);
  }
}

.cdk-overlay-pane.my-dialog {
  position: relative !important;
}

.dialog-no-padding .mat-mdc-dialog-container {
  padding: 0;
  .close.mat-mdc-button {
    top: 0;
    right: 0;
  }
}

.close.mat-mdc-button {
  --mat-text-button-with-icon-horizontal-padding: 7px;
  --mdc-text-button-container-height: 34px;
  position: absolute;
  top: -22px;
  right: -22px;
  line-height: 14px;
  min-width: auto;
  z-index: 2;

  & > .mat-icon {
    --mat-text-button-icon-spacing: 0;
  }
}

// Tables
table {
  width: 100%;
}

.table-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

.table-container {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  overflow: auto;
  height: 80vh;
}

.loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 102;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table > thead,
.table > tbody {
  vertical-align: middle;
}

.table {
  td, th {
    padding: 0;
  }

  th.mat-mdc-header-cell:first-of-type,
  td.mat-mdc-cell:first-of-type,
  td.mat-mdc-footer-cell:first-of-type {
    padding-left: 24px;
  }
}

.table > :not(:first-child) {
  border-top: 0;
}

// Custom table typography
th.mat-mdc-header-cell {
  background: #F2F3F8 !important;
}
th.mat-mdc-header-cell,
td.mat-mdc-footer-cell {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #47464B;
}

td.mat-mdc-cell {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #262626;
}

// Editor
.NgxEditor__Content {
  height: 100px;
  overflow-x: auto;
  overflow-y: hidden;
}

// tabs
.mat-mdc-tab-group.mat-primary .mat-ink-bar {
  background-color: #f47121;
}

// Align with menu text
.menu-align-padding-left {
  padding-left: 28px;
}

// image dialog
app-image-dialog {
  width: 100%;
  height: 100%;
}
// avatar
.item-photo {
  width: 40px;
  height: 40px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;

  &.has-image {
    background-size: contain;
    cursor: pointer;
  }
}

.under-table {
  h3,
  p {
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #262626;
    margin-bottom: 0;
  }
}

// section button
.mdc-button.add-section {
  font-size: 18px;
  line-height: 32px;
  color: #6096f6;
  text-transform: uppercase;
  --mat-text-button-horizontal-padding: 16px;
  --mdc-text-button-container-height: 36px;
  white-space: nowrap;
}

.gray-icon {
  color: #ccd2e3;
}
.dark-gray-icon {
  color: #666670;
}

.blue-button {
  color: #6096f6;
}

.red-button {
  color: #f5515f;
}

.active-row {
  background-color: #f2f3f8;
}

.custom-label {
  --mdc-filled-text-field-label-text-size: 12px;
  --mdc-filled-text-field-label-text-weight: 700;
  --mdc-filled-text-field-label-text-tracking: 0.1px;
   label {
    text-transform: uppercase;
    color: #97989b;
  }
}

.np {
  .mdc-text-field {
    padding: 0;
  }
}

.with-border {
  .mat-mdc-text-field-wrapper {
    border-bottom: 1px #8d8d90 solid;
  }
}

.autocomplete-input {
  margin-top: 9px;
  &-icon {
    position: absolute;
    top: 3px;
    right: 0;
  }
}

.search {
  &-filter-button {
    padding: 0 25px;
  }
  &-input {
    width: 270px;
    height: 44px;
    padding: 10px;
    background-color: #ffffff;
    border: 1px solid #dadada;
    border-radius: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #666670;
    text-indent: 27px;
    &:focus {
      box-shadow: none;
    }
    &-wrapper {
      position: relative;
      mat-icon {
        position: absolute;
        color: #666670;
        top: 11px;
        left: 8px;
      }
    }

    &.full {
      width: 100%;
    }
  }
}

// Instro JS
.introjs-skipbutton {
  top: 2px;
  right: 17px;
}

// Custom card
.custom-card {
  border: 1px solid #dadada;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.widget {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 0 7px #00000026;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  .empty {
    max-width: 200px;
    margin: 150px auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    color: #6096f6;
  }
}

.mat-mdc-option {
  font-size: inherit;
}

.no-padding {
  mat-select {
    --mat-select-trigger-text-size: 15px;
  }

  .mat-form-field-appearance-fill {
    .mat-mdc-text-field-wrapper {
      --mdc-filled-text-field-container-color: transparent;
      --mat-form-field-hover-state-layer-opacity: 0;
      padding: 0;
      width: 120px;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.report-filter {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }

  .mat-mdc-form-field-icon-suffix {
    top: -5px;
  }
}

.align-right {
  text-align: right;
}

.cdk-overlay-pane {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 96%;
  border-radius: 4px;
  --mat-dialog-container-max-width: 94vw;
}

app-instrument-form-tabs {
  .mat-mdc-tab-nav-bar,
  .mat-mdc-tab-header {
    border-bottom: 0;
  }
}

.swiper-slide {
  position: relative;

  .image-wrap {
    aspect-ratio : 1 / 1;
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Centering y-axis */
    align-items :center;

    img {
      width: 100%;
    }
  }

  .file-actions {
    position: absolute;
    right: 5px;
    top: 5px;
    height: 40px;
    width: 84px;
    text-align: right;

    button {
      background: #ffffff60;
      border-radius: 50%;
      margin-left: 4px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

swiper {
  --swiper-navigation-size: 34px;
  --swiper-pagination-bullet-size: 12px;
}

.swiper-button-prev, .swiper-rtl .swiper-button-next,
.swiper-button-next, .swiper-rtl .swiper-button-prev {
  top: auto;
  bottom: 5px;
  z-index: 11;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 12px;
}

.mat-mdc-dialog-container {
  position: relative;
}

.results {
  .highlight {
    color: #666670;
    font-weight: 400;
  }
}

.sub-menu {
  margin-left: 30px;

  button {
    cursor: pointer;
  }
}

.dark {
  .sub-menu {

    button {
      color: #AFB1B5;
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.menu-icon {
  &.only-dark {
    display: none;
  }
}

.dark {
  .menu-icon {
    display: none;

    &.only-dark {
      display: block;
    }
  }
}

.mat-mdc-menu-content {
  --mat-menu-item-label-text-tracking: 0.01;

  padding: 0 !important;

  .mat-divider {
    --mat-menu-divider-bottom-spacing: 15px;
    --mat-menu-divider-top-spacing: 10px;
  }

  .mat-mdc-menu-item {
    height: 30px;
    line-height: 30px;
    min-height: 36px;
  }
}

.mat-mdc-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: transparent;
}

.mat-mdc-menu-panel {
  border: 1px solid #cbcbcb;
  backdrop-filter: blur(2px);
  border-radius: 6px;
  max-width: 300px;
}

.toolbar {
  .blue-button {
    background: #0D66ED;
    color: #FFFFFF;
    border: 1px solid #DADADA;
    border-radius: 40px;
    box-shadow: 0 0 0;
    line-height: 42px;
    --mdc-text-button-label-text-color: #FFFFFF;
  }

  .search-filter-button {
    border: 1px solid #DADADA;
    border-radius: 40px;
  }
}

.small-menu .mat-mdc-menu-content {
  padding-top: 8px !important;
  padding-bottom: 8px !important;

  button {
    &.accent {
      color: #0D66ED;

      .mat-icon {
        color: #0D66ED;
      }
    }

    &.warn {
      color: #F5515F;

      .mat-icon {
        color: #F5515F;
      }
    }
  }
}

.center .mat-sort-header-container {
  justify-content: center;
}

.mat-sort-header-container {
  border: 0 !important;
}

.mdc-text-field--filled .mdc-line-ripple {
  display: none;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  padding: 0;
}

.mat-mdc-icon-button .mat-mdc-button-touch-target {
  width: 40px !important;
  height: 40px !important;
}

ng-scrollbar {
  height: 100%;
}

.mat-mdc-card {
  padding: 16px;
}

.mdc-text-field--filled {
  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-hover-state-layer-opacity: 0;
  --mat-form-field-focus-state-layer-opacity: 0;
}

mat-form-field {
  --mat-form-field-container-text-tracking: 0.01;
}

mat-card-actions {
  &.mat-mdc-card-actions {
    margin-left: -10px;
    margin-right: -10px;
    padding: 8px 0;
  }

  button {
    margin: 0 4px;
  }

  .row {
    width: 100%;
  }
}

mat-dialog-container {
  .mat-mdc-dialog-surface {
    padding: 24px;
  }
}

app-instrument-edit {
  .mdc-tab {
    min-width: 170px;
  }

  .mat-mdc-tab-body {
    overflow: hidden !important;
  }

  .mat-mdc-tab-body-content {
    overflow: hidden;
  }
}

.main-info {
  .mat-mdc-select-arrow-wrapper {
    padding-left: 5px;
  }
}

.mat-mdc-table {

  .mdc-data-table__cell,
  .mdc-data-table__header-cell {
    padding: 0 5px;

    &:first-child {
      padding-left: 16px;
    }
  }
}

